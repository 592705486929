import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DownLoadStatsReport,
  GetFilterClinic,
  GetAllProvince,
  GetFilterDistricts,
  GetFilterSubDistricts,
} from '@ecdlink/graphql';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Button,
  Dropdown,
  SearchDropDown,
  SearchDropDownOption,
  Typography,
} from '@ecdlink/ui';
import { b64toBlob } from '@ecdlink/core';
import { format } from 'date-fns';
import ReactDatePicker from 'react-datepicker';

export function StatsReporting() {
  const [statsDownloaded, setStatsDownloaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [filterDateAdded, setFilterDateAdded] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const CHWConnect_Data_Extracts_Codesheet =
    'https://ecdconnectstoragedev.blob.core.windows.net/unknown/44f7ba46-fc3c-40dd-bfac-72aa042987af_CHW_Connect_Data_Extracts_Codesheet_FINAL_02Aug24.xlsx';

  const dateDropdownValue = useMemo(
    () =>
      startDate && endDate
        ? `${format(startDate, 'd MMM yy')} - ${format(endDate, 'd MMM yy')}`
        : '',
    [endDate, startDate]
  );

  const handleSetDateFilter = useCallback(() => {
    setFilterDateAdded(!filterDateAdded);
  }, [filterDateAdded]);

  useEffect(() => {
    if (endDate) {
      handleSetDateFilter();
    }
  }, [endDate]);

  // Provinces
  const { data: provinceData, loading: loadingProvince } = useQuery(
    GetAllProvince,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const [provinces, setProvinces] = useState<SearchDropDownOption<string>[]>(
    []
  );
  const [provincesFiltered, setProvincesFiltered] = useState<
    SearchDropDownOption<string>[]
  >([]);
  const filteredProvinces = useMemo(
    () => provincesFiltered?.map((item) => item?.id),
    [provincesFiltered]
  );

  useEffect(() => {
    if (provinceData?.GetAllProvince?.length > 0) {
      const provincesSorted = provinceData?.GetAllProvince?.slice()?.sort(
        (a, b) =>
          a.description < b.description
            ? -1
            : a.description > b.description
            ? 1
            : 0
      );

      setProvinces(
        provincesSorted
          ?.filter((prov) => prov?.description !== 'N/A')
          ?.map((item) => {
            return {
              value: item?.id,
              label: item?.description,
              id: item?.id,
            };
          })
      );
    }
  }, [provinceData?.GetAllProvince]);

  // District
  const { data: districtData, loading: loadingDistricts } = useQuery(
    GetFilterDistricts,
    {
      variables: {
        provinceIds: provincesFiltered?.map((item) => item?.id),
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const [districts, setDistricts] = useState<SearchDropDownOption<string>[]>();
  const [districtsFiltered, setDistrictsFiltered] = useState<
    SearchDropDownOption<string>[]
  >([]);
  const filteredDistricts = useMemo(
    () => districtsFiltered?.map((item) => item?.id),
    [districtsFiltered]
  );

  useEffect(() => {
    if (districtData) {
      if (districtData?.districtsForProvinceId.length > 0) {
        const districtSorted = districtData?.districtsForProvinceId
          ?.slice()
          ?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

        setDistricts(
          districtSorted?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
              id: item?.id,
            };
          })
        );
      }
    } else {
      setDistricts([]);
    }
  }, [districtData, districtData?.districtsForProvinceId]);

  // Sub-district
  const { data: subDistrictData, loading: loadingSubDistricts } = useQuery(
    GetFilterSubDistricts,
    {
      variables: {
        provinceIds: provincesFiltered?.map((item) => item?.id),
        districtIds: districtsFiltered?.map((item) => item?.id),
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const [subDistricts, setSubDistricts] =
    useState<SearchDropDownOption<string>[]>();
  const [subDistrictsFiltered, setSubDistrictsFiltered] = useState<
    SearchDropDownOption<string>[]
  >([]);
  const filteredSubDistricts = useMemo(
    () => subDistrictsFiltered?.map((item) => item?.id),
    [subDistrictsFiltered]
  );

  useEffect(() => {
    if (subDistrictData) {
      if (subDistrictData?.subDistrictsForDistrictId?.length > 0) {
        const subDistrictSorted = subDistrictData?.subDistrictsForDistrictId
          ?.slice()
          ?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
        setSubDistricts(
          subDistrictSorted?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
              id: item?.id,
            };
          })
        );
      } else {
        setSubDistricts([]);
      }
    }
  }, [subDistrictData]);

  // Clinics
  const { data: clinicData, loading: loadingClinics } = useQuery(
    GetFilterClinic,
    {
      variables: {
        provinceIds: provincesFiltered?.map((item) => item?.id),
        districtIds: districtsFiltered?.map((item) => item?.id),
        subDistrictIds: subDistrictsFiltered?.map((item) => item?.id),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [clinics, setClinics] = useState<SearchDropDownOption<string>[]>([]);
  const [clinicsFiltered, setClinicsFiltered] = useState<
    SearchDropDownOption<string>[]
  >([]);
  const filteredClinics = useMemo(
    () => clinicsFiltered?.map((item) => item?.label),
    [clinicsFiltered]
  );

  useEffect(() => {
    if (clinicData) {
      if (clinicData?.clinicsForFilter?.length > 0) {
        const clinicsSorted = clinicData?.clinicsForFilter
          ?.slice()
          ?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
        setClinics(
          clinicsSorted?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
              id: item?.id,
            };
          })
        );
      } else {
        setClinics([]);
      }
    }
  }, [clinicData]);

  const queryVariables = useMemo(
    () => ({
      clinicSearch: filteredClinics,
      provinceSearch: filteredProvinces,
      subDistrictSearch: filteredSubDistricts,
      startDate: startDate,
      endDate: endDate,
    }),
    [
      endDate,
      filteredClinics,
      filteredProvinces,
      filteredSubDistricts,
      startDate,
    ]
  );

  const [downLoadStatsReport, { data: reportData }] = useLazyQuery(
    DownLoadStatsReport,
    {
      fetchPolicy: 'network-only',
      variables: queryVariables,
    }
  );

  useEffect(() => {
    if (reportData && reportData.downloadGGStatsFile) {
      setIsLoading(false);

      const b64Data = reportData.downloadGGStatsFile.base64File;
      const contentType = reportData.downloadGGStatsFile.fileType;
      const fileName = reportData.downloadGGStatsFile.fileName;
      const extension = reportData.downloadGGStatsFile.extension;
      const blob = b64toBlob(b64Data, contentType);

      const link = document.createElement('a');

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${fileName}${extension}`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setStatsDownloaded(true);
    }
  }, [reportData]);

  const clearFilters = () => {
    setProvincesFiltered([]);
    setDistrictsFiltered([]);
    setSubDistrictsFiltered([]);
    setClinicsFiltered([]);
    setEndDate(null);
    setStartDate(null);
  };

  const downloadData = () => {
    setIsLoading(true);
    downLoadStatsReport();
  };

  return (
    <div className="bg-adminPortalBg h-full rounded-2xl p-4 ">
      <div className="rounded-xl bg-white p-12">
        <span className="pl-2 font-semibold text-black">Filters:</span>
        <div className="mt-4 flex flex-row items-center justify-between sm:mt-6">
          <div className="mr-2 flex items-center gap-2">
            {!filterDateAdded && (
              <div
                className="min-w mr-2 flex items-center gap-2"
                onClick={() => setFilterDateAdded(!filterDateAdded)}
              >
                <Dropdown
                  fillType="filled"
                  textColor={'textLight'}
                  fillColor={endDate ? 'secondary' : 'white'}
                  placeholder={dateDropdownValue || 'Report Period'}
                  labelColor={endDate ? 'white' : 'textLight'}
                  list={[]}
                  onChange={(item) => {}}
                  className="w-56 text-sm text-white"
                />
              </div>
            )}

            {filterDateAdded && (
              <ReactDatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange={true}
                inline
                shouldCloseOnSelect={true}
              />
            )}
            <SearchDropDown<string>
              displayMenuOverlay={true}
              className={'mr-1'}
              menuItemClassName={
                'w-11/12 left-4 h-60 overflow-y-scroll bg-adminPortalBg'
              }
              overlayTopOffset={'120'}
              options={provinces}
              selectedOptions={provincesFiltered}
              onChange={setProvincesFiltered}
              placeholder={'Province'}
              multiple={true}
              color={'secondary'}
              info={{
                name: `Provinces:`,
              }}
            />

            <SearchDropDown<string>
              displayMenuOverlay={true}
              className={'mr-1'}
              menuItemClassName={
                'w-11/12 left-4 h-60 overflow-y-scroll bg-adminPortalBg'
              }
              overlayTopOffset={'120'}
              options={districts}
              selectedOptions={districtsFiltered}
              onChange={setDistrictsFiltered}
              placeholder={'District'}
              multiple={true}
              color={'secondary'}
              info={{
                name: `Districts:`,
              }}
            />

            <SearchDropDown<string>
              displayMenuOverlay={true}
              className={'mr-1'}
              menuItemClassName={
                'w-11/12 left-4 h-60 overflow-y-scroll bg-adminPortalBg'
              }
              overlayTopOffset={'120'}
              options={subDistricts}
              selectedOptions={subDistrictsFiltered}
              onChange={setSubDistrictsFiltered}
              placeholder={'Sub-district'}
              multiple={true}
              color={'secondary'}
              info={{
                name: `Sub-districts:`,
              }}
            />

            <SearchDropDown<string>
              displayMenuOverlay={true}
              className={'mr-1'}
              menuItemClassName={
                'w-11/12 left-4 h-60 overflow-y-scroll bg-adminPortalBg'
              }
              overlayTopOffset={'120'}
              options={clinics}
              selectedOptions={clinicsFiltered}
              onChange={setClinicsFiltered}
              placeholder={'Clinic'}
              multiple={true}
              color={'secondary'}
              info={{
                name: `Clinics:`,
              }}
            />

            <button
              onClick={clearFilters}
              type="button"
              className="bg-secondary hover:bg-uiLight focus:outline-none inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2"
            >
              Clear all
            </button>
            <a
              href={CHWConnect_Data_Extracts_Codesheet}
              download="codesheet"
              className="bg-secondary hover:bg-uiLight focus:outline-none inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2"
            >
              Download Codesheet
            </a>
          </div>
        </div>

        <div
          className="mt-4"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            className={'mt-3 w-full'}
            type="filled"
            isLoading={isLoading}
            color="secondary"
            disabled={startDate === null && endDate === null}
            onClick={downloadData}
          >
            <Typography
              type="help"
              color="white"
              text={'Download report'}
            ></Typography>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default StatsReporting;
